import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { logoutUser } from '../../actions/Auth';
import { getInformationViews, getInventoryViews } from '../../selectors/View';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { getActiveConfiguration } from '../../selectors/Configuration';
import { getActiveClient as getActiveClientObject } from '../../selectors/Client';
import useShortcodes from '../../hooks/ShortcodeHook';
import Accordion from '../Accordion';
import AssetGallery from '../AssetGallery';
import packageJson from '../../../../package.json';
import './View.scss';
import c, { SearchType, isDevOrLocal } from '../../helpers/constants';
import Loading from '../Loading';
import ImageButton from '../ImageButton';
import {
  formatUrl,
  getActiveClient,
  getActiveUserSubscription,
  getUserToken,
} from '../../helpers/functions';
import { createOrUpdatePopularSearch } from '../../actions/PopularSearch';
import gallery from '../AssetGallery/AssetGallery';
import BugReportLink from '../MailtoTemplates/BugReportLink';
import Modal from '../Modal';
import ProfileToggle from '../ProfileToggle/ProfileToggle';
import { fetchActiveClient } from '../../actions/Client';

type Props = {
  children?: React.ReactNode;
  title?: string;
  onAddNew?: () => void;
  onEdit?: () => void;
  onShare?: () => void;
  isLoading?: boolean;
  bannerType?: SearchType;
  bannerSearchTerm?: string | null;
  imgBanner?: string | undefined;
  smallImgBanner?: string | undefined;
  bannerTitle?: string | undefined;
};

const View = (props: Props) => {
  const { children, isLoading, title, onAddNew, onEdit, onShare, bannerType, imgBanner, smallImgBanner, bannerTitle, bannerSearchTerm } = props;
  const history = useHistory();
  const configuration = useSelector(getActiveConfiguration);
  const activeUser = useSelector(getLoggedInUser);

  const appVersion = packageJson.version;
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const {
    isUsingShortcodes,
    clientShortcode,
    groupShortcode,
  } = useShortcodes(c.APP_ROUTES.DASHBOARD_SHORTCODE, c.APP_ROUTES.DASHBOARD);

  const dispatch = useDispatch();
  const loggedInRole = useSelector(getLoggedInRole);
  const informationViews = useSelector(getInformationViews);
  const inventoryViews = useSelector(getInventoryViews);
  const activeClient = useSelector(getActiveClientObject);
  const selectedClientId = getActiveClient().id;
  const selectedClientTitle = getActiveClient().title;
  const isReturnSubscriptionPage = location.pathname === c.APP_ROUTES.RETURN || location.pathname === c.APP_ROUTES.SUBSCRIPTION;
  const userHasActiveSubscription = getActiveUserSubscription(activeUser, selectedClientId);

  const showAdminSubscriptionPrompt = (!userHasActiveSubscription.isPremium && !userHasActiveSubscription.hasActiveSubscription && loggedInRole?.accessLevel === 1000 && !isReturnSubscriptionPage);
  const showUserSubscriptionPrompt = (!userHasActiveSubscription.isPremium && !userHasActiveSubscription.hasActiveSubscription && loggedInRole && loggedInRole?.accessLevel > 1000 && !isReturnSubscriptionPage);

  useEffect(() => {
    if (!activeClient && selectedClientId){
      dispatch(fetchActiveClient(selectedClientId));
    }
  }, [selectedClientId]);

  const onLogout = () => {
    dispatch(logoutUser());
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const onLogin = () => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      history.push(formatUrl(c.APP_ROUTES.USER_LOGIN_SHORTCODE, groupShortcode, clientShortcode));
    } else {
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
  };

  const goBack = () => {
    if (bannerType === SearchType.MARKETPLACE){
      if (isUsingShortcodes){
        if (bannerSearchTerm){
          history.push(formatUrl(c.APP_ROUTES.SEARCH_MARKETPLACES_SHORTCODE, groupShortcode, clientShortcode, bannerSearchTerm));
        } else {
          history.push(formatUrl(c.APP_ROUTES.SEARCH_ENGINE_SHORTCODE, groupShortcode, clientShortcode, 'marketplace'));
        }
      } else {
        if (bannerSearchTerm){
          history.push(formatUrl(c.APP_ROUTES.SEARCH_MARKETPLACES, bannerSearchTerm));
        } else {
          history.push(formatUrl(c.APP_ROUTES.SEARCH_ENGINE, 'marketplace'));
        }
      }
    } else if (bannerType === SearchType.AUCTION){
      if (isUsingShortcodes){
        if (bannerSearchTerm){
          history.push(formatUrl(c.APP_ROUTES.SEARCH_MARKETPLACES_SHORTCODE, groupShortcode, clientShortcode, bannerSearchTerm));
        } else {
          history.push(formatUrl(c.APP_ROUTES.SEARCH_ENGINE_SHORTCODE, groupShortcode, clientShortcode, 'auction'));
        }
      } else {
        if (bannerSearchTerm) {
          history.push(formatUrl(c.APP_ROUTES.SEARCH_AUCTIONS, bannerSearchTerm));
        } else {
          history.push(formatUrl(c.APP_ROUTES.SEARCH_ENGINE, 'auction'));
        }
      }
    }
  };

  const addPopularSearch = (type: string, searchTerm: string) => {
    dispatch(createOrUpdatePopularSearch(type, searchTerm));
  };


  const renderSearchEngine = (menuTitle: string, type: string, img?: string) => {
    const menuData = JSON.parse(localStorage.getItem('searchData') || '{}')[type] || [];
    const constructLink = (path: string, searchTerm: string, isClientLink?: boolean) => {
      if (path === SearchType.MARKETPLACE) {
        const baseUrl = (!activeUser && isUsingShortcodes) ? formatUrl(c.APP_ROUTES.VIEW_MARKETPLACES_SHORTCODE, groupShortcode, clientShortcode, searchTerm) : formatUrl(c.APP_ROUTES.VIEW_MARKETPLACES, searchTerm);
        return isClientLink ? `${baseUrl}?client=${isClientLink}` : baseUrl;

      } else {
        const baseUrl = formatUrl(c.APP_ROUTES.VIEW_AUCTIONS, searchTerm);
        return isClientLink ? `${baseUrl}?client=${isClientLink}` : baseUrl;
      }
    };
    let navLinks = menuData.map((item: any) => {
      return constructLink(type, item);
    });
    navLinks.push(`/search-engine/${type}`);
    navLinks.push(`/${type}/create/details`);

    // Rendering My Client's Marketplaces/Auctions
    let myClientsSection;
    if (activeUser?.clients.length === 1) {
      const client = activeUser.clients[0];
      myClientsSection = (
                <NavLink
                    key={client.clientId}
                    className="navbar-brand view__sidebar__side-search"
                    to={constructLink(type, client.clientTitle, true)}
                >
                    {`My Client's ${menuTitle}`}
                </NavLink>
      );
    } else if (selectedClientId) {
      myClientsSection = (
            <NavLink
                key={selectedClientId}
                className="navbar-brand view__sidebar__side-search"
                to={constructLink(type, selectedClientTitle ?? 'fallback', true)}
            >
                {`My Client's ${menuTitle}`}
            </NavLink>
      );
    } else if (activeUser && activeUser?.clients.length > 1) {
      const myClientsAccordion = activeUser?.clients.map((client: any) => {
        return (
                    <NavLink
                        key={client.clientId}
                        className="nav-link view__sidebar__side-link"
                        to={constructLink(type, client.clientTitle, true)}
                    >
                        {client.clientTitle}
                    </NavLink>
        );
      });
      myClientsSection = (
                <Accordion title={`My Client's ${menuTitle}`} active={'1'} customClassname={'my-client-accordion'}>
                    <ul className="nav flex-column">
                        {myClientsAccordion}
                    </ul>
                </Accordion>
      );
    } else if (!activeUser && isUsingShortcodes && configuration){
      myClientsSection = (
            <NavLink
                key={configuration.clientId}
                className="navbar-brand view__sidebar__side-search"
                to={constructLink(type, configuration.clientTitle, true)}
            >
                {`My Client's ${menuTitle}`}
            </NavLink>
      );
    }

    return (
            <>
                <Accordion title={menuTitle} active={'1'} navLinks={navLinks} icon={img}>
                    <ul className="nav flex-column">
                        {(getUserToken() && loggedInRole && loggedInRole?.accessLevel <= 1000) && <NavLink className="navbar-brand view__sidebar__side-search" to={`/${type}/create/details`}>Create New {type.charAt(0).toUpperCase() + type.slice(1)}</NavLink>}
                        {myClientsSection}
                        <NavLink className="navbar-brand view__sidebar__side-search" to={(!activeUser && isUsingShortcodes) ? `/${groupShortcode}/${clientShortcode}/search-engine/${type}` : `/search-engine/${type}`}>Search</NavLink>
                        {(menuData && menuData?.length > 0) &&
                            <>
                                <NavLink className="navbar-brand view__sidebar__side-brand" to="#">Recent Searches</NavLink>
                                {menuData.map((searchTerm: any, index: number) => (
                                    <NavLink
                                        key={index}
                                        className="nav-link view__sidebar__side-link"
                                        to={constructLink(type, searchTerm)}
                                        onClick={() => addPopularSearch(type, searchTerm)}
                                    >
                                        {searchTerm}
                                    </NavLink>
                                ))}
                            </>
                        }
                    </ul>
                </Accordion>
            </>
    );
  };

  const renderCategory = (menuTitle: string, menuData: any, linkPath: string, img?: string) => {
    if ((!activeUser || !activeUser.clients || activeUser.clients.length === 0) && !isUsingShortcodes) {
      // If there are no clients or user data available, return null or handle accordingly
      return null;
    }

    if ((activeUser && activeUser.clients.length === 1) || selectedClientId || isUsingShortcodes) {
      // If the user belongs to only one client, render all views under a single accordion
      if (selectedClientId){
        menuData = menuData.filter((item: any) => item.clientId === selectedClientId);
      }

      if (menuData.length === 0) {
        return (
                <Accordion title={menuTitle} active={'1'} icon={img}>
                    <span className="view__sidebar__span">No views found.</span>
                </Accordion>
        );
      }

        
      const navLinks = menuData.map((item: any) => {
        return (isUsingShortcodes && !activeUser)
          ? `/${groupShortcode}/${clientShortcode}/${linkPath}/${item.id}`
          : `/${linkPath}/${item.id}`;
      });

      return (
                <Accordion title={menuTitle} active={'1'} navLinks={navLinks} icon={img}>
                    <ul className="nav flex-column">
                        {menuData.map((item: any, idx: number) => (
                            <NavLink
                                key={idx}
                                className="nav-link view__sidebar__side-link"
                                to={
                                    (isUsingShortcodes && !activeUser)
                                      ? `/${groupShortcode}/${clientShortcode}/${linkPath}/${item.id}`
                                      : `/${linkPath}/${item.id}`
                                }
                            >
                                {item.title}
                            </NavLink>
                        ))}
                    </ul>
                </Accordion>
      );
    }

    // If the user belongs to multiple clients, render each client's views under separate accordions
    const accordions = activeUser?.clients.sort((a, b) => a.clientTitle.localeCompare(b.clientTitle)).map((client: any, index: number) => {
      const clientMenuData = menuData.filter((item: any) => item.clientTitle === client.clientTitle);

      if (clientMenuData.length > 0) {
        const navLinks = clientMenuData.map((item: any) => {
          return isUsingShortcodes
            ? `/${groupShortcode}/${clientShortcode}/${linkPath}/${item.id}`
            : `/${linkPath}/${item.id}`;
        });

        return (
                    <Accordion key={index} title={`${client.clientTitle}`} customClassname={'my-client-accordion'} active={'1'} navLinks={navLinks}>
                        <ul className="nav flex-column">
                            {clientMenuData.map((item: any, idx: number) => (
                                <NavLink
                                    key={idx}
                                    className="nav-link view__sidebar__side-link"
                                    to={
                                        isUsingShortcodes
                                          ? `/${groupShortcode}/${clientShortcode}/${linkPath}/${item.id}`
                                          : `/${linkPath}/${item.id}`
                                    }
                                >
                                    {item.title}
                                </NavLink>
                            ))}
                        </ul>
                    </Accordion>
        );
      }

      return null;
    });

    // Render the main accordion with nested accordions for each client
    return (
        <div className={'mb-2'}>
            <Accordion title={menuTitle} active={'1'} icon={img}>
                <ul className="nav flex-column">
                    {accordions}
                </ul>
            </Accordion>
        </div>
    );
  };

  const renderAdminMenu = () => {
    if (loggedInRole?.accessLevel === 0) {
      return (
                <>
                    <Accordion title={'Client'} navLinks={c.APP_ROUTES.CLIENT_NAV_LINKS} icon={AssetGallery.clientImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/group'>Groups</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/client'>Clients</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/view'>Views</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/asset'>Assets</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/configuration'>Configurations</NavLink>
                    </Accordion>
                    <Accordion title={'User'} navLinks={c.APP_ROUTES.USER_NAV_LINKS} icon={AssetGallery.userImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/user'>Users</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/verify'>Verify Users</NavLink>
                        {loggedInRole?.accessLevel === 0 && <NavLink className="nav-link view__sidebar__side-link" to="/admin/lead">Leads</NavLink>}
                    </Accordion>
                    <Accordion title={'Role'} navLinks={c.APP_ROUTES.ROLE_NAV_LINKS} icon={AssetGallery.roleImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/role'>Roles</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/role-group'>Role/Group</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/role-client'>Role/Client</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/view-role'>View/Role</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/form-field-role'>FormField/Role</NavLink>
                    </Accordion>
                    <Accordion title={'Entry'} navLinks={c.APP_ROUTES.ENTRY_NAV_LINKS} icon={AssetGallery.entryImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry'>Entries</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/shadow-entry'>Shadow
                            Entries</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-field'>Entry
                            Fields</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-edge'>Entry
                            Connections</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-history'>Entry
                            History</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-field-history'>Entry
                            Field History</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-tag'>Entry/Tag</NavLink>
                    </Accordion>
                    <Accordion title={'Form'} navLinks={c.APP_ROUTES.FORM_NAV_LINKS} icon={AssetGallery.formImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/form'>Forms</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/form-field'>Form
                            Fields</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/field-type'>FieldTypes</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/field-type-option'>FieldTypeOptions</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/field'>Fields</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/field-property'>Field
                            Properties</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/category'>Categories</NavLink>
                    </Accordion>
                    {(loggedInRole?.accessLevel === 0 || isDevOrLocal)  && <Accordion title={'Subscription'} navLinks={c.APP_ROUTES.SUBSCRIPTION_NAV_LINKS} icon={AssetGallery.catalogueImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/subscription-transaction'>Subscription</NavLink>

                            <>
                                <NavLink className="nav-link view__sidebar__side-link" to="/admin/subscription-admin">Subscriptions
                                    Table</NavLink>
                                <NavLink className="nav-link view__sidebar__side-link" to='/admin/plan'>Plan</NavLink>
                                <NavLink className="nav-link view__sidebar__side-link" to='/admin/transaction'>Transaction</NavLink>
                                <NavLink className="nav-link view__sidebar__side-link" to='/admin/opp-notification'>OPP Notifications</NavLink>
                            </>
                    </Accordion>}
                    <Accordion title={'Marketplace'} navLinks={c.APP_ROUTES.MARKETPLACE_NAV_LINKS} icon={AssetGallery.marketplaceSettingsImg}>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/marketplace'>Marketplaces</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/marketplace-entry'>Marketplace/Entry</NavLink>
                    </Accordion>
                    <Accordion title={'Auction'} navLinks={c.APP_ROUTES.AUCTION_NAV_LINKS} icon={AssetGallery.auctionSettingsImg}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/auction'>Auction</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/auction-bid'>Auction
                            Bid</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/auction-entry'>Auction
                            Entry</NavLink>
                    </Accordion>
                    <Accordion title={'Matchmaking'} navLinks={c.APP_ROUTES.MATCHMAKING_NAV_LINKS} icon={AssetGallery.matchmakingImg}>
                        <NavLink className="nav-link view__sidebar__side-link " to='/admin/badge'>Badge</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/solution'>Solution</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/tag'>Tag</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/function'>Function</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/solution-badge'>Solution/Badge</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/solution-function'>Solution/Function</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/solution-input-tag'>SolutionInput/Tag</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/solution-output-tag'>SolutionOutput/Tag</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/match'>Match</NavLink>
                    </Accordion>
                    <Accordion title={'ABB Related'} navLinks={c.APP_ROUTES.ABB_NAV_LINKS}>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/contact'>Contacts</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/composition'>Compositions</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry-composition'>Entry Compositions</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/status-code'>Status Codes</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/offer'>Offers</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/offer-item'>Offer Items</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/offer-project-manager'>Offer Project Managers</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/offer-status-code'>Offer Status Codes</NavLink>
                        <NavLink className="nav-link view__sidebar__side-link" to='/admin/offer-item-status-code'>Offer Item Status Codes</NavLink>
                    </Accordion>
                    <Accordion title={'Popular Search'} navLinks={c.APP_ROUTES.MARKETPLACE_NAV_LINKS} icon={AssetGallery.popularSettingsImg}>
                        <NavLink className="nav-link view__sidebar__side-link"
                                 to='/admin/popular-search'>Popular Search</NavLink>
                    </Accordion>
                </>
      );
    }
  };

  const renderClientAdminMenu = () => {
    if (true) {
      return (
          <>
              <Accordion title={'User'} navLinks={c.APP_ROUTES.USER_NAV_LINKS} icon={AssetGallery.userImg}>
                  <NavLink className="nav-link view__sidebar__side-link" to='/admin/user'>Users</NavLink>
                  <NavLink className="nav-link view__sidebar__side-link" to='/verify'>Verify Users</NavLink>
              </Accordion>
              <Accordion title={'Client'} navLinks={c.APP_ROUTES.USER_NAV_LINKS} icon={AssetGallery.userImg}>
                  <NavLink className="nav-link view__sidebar__side-link" to='/admin/marketplace'>Marketplaces</NavLink>
                  <NavLink className="nav-link view__sidebar__side-link" to='/admin/auction'>Auctions</NavLink>
                  <NavLink className="nav-link view__sidebar__side-link" to='/admin/entry'>Entries</NavLink>
              </Accordion>
              {(loggedInRole?.accessLevel === 0 || isDevOrLocal) && <Accordion title={'Subscription'} navLinks={c.APP_ROUTES.SUBSCRIPTION_NAV_LINKS} icon={AssetGallery.catalogueImg}>
                  <NavLink className="nav-link view__sidebar__side-link" to='/admin/subscription-transaction'>Subscription</NavLink>

                      <>
                      <NavLink className="nav-link view__sidebar__side-link" to="/admin/subscription-admin">Subscriptions
                      Table</NavLink>
                      <NavLink className="nav-link view__sidebar__side-link" to='/admin/plan'>Plan</NavLink>
                      <NavLink className="nav-link view__sidebar__side-link" to='/admin/transaction'>Transaction</NavLink>
                      <NavLink className="nav-link view__sidebar__side-link" to='/admin/opp-notification'>OPP Notifications</NavLink>
                      </>

              </Accordion>}
          </>
      );
    }
    return null;
  };

  const handleLogoClick = () => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      history.push(formatUrl(c.APP_ROUTES.DASHBOARD_SHORTCODE, groupShortcode, clientShortcode));
    } else {
      history.push('/');
    }
  };

  const renderHeader = () => {
    return (<div className="d-flex align-items-center mb-4 view__sidebar__logo__container"
                     onClick={handleLogoClick}>
            <img src={AssetGallery.emeLogo} alt="Logo" className="view__sidebar__logo__img"/>
            <span className="ml-2 view__sidebar__logo__text">
                                      EXCESS<br/>
                                      MATERIALS<br/>
                                      EXCHANGE
                                    </span>
        </div>);
  };

  const renderBanner = (banner: SearchType | undefined) =>{
    if (!banner){
      return null;
    } else {
      return (
              <div className='view__body__banner'>
                  {onEdit && <img src={gallery.editImg} onClick={onEdit} alt="" className='view__body__banner__edit-img' />}
                  {onShare && <img src={gallery.shareImgBlueBG} onClick={onShare} alt="" className='view__body__banner__share-img' />}
                  <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${imgBanner}`} alt="" className='view__body__banner__background-img' />
                  <div className='view__body__banner__line'>
                      <div className={banner === SearchType.MARKETPLACE ? 'view__body__banner__line-items' : 'view__body__banner__line-items view__body__banner__line-items--auction'}>
                          {smallImgBanner && <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${smallImgBanner}`} alt="" className='view__body__banner__client-logo' />}
                          <img src={banner === SearchType.MARKETPLACE ? gallery.marketplaceImg : gallery.auctionsImg} onClick={goBack} alt="" className='view__body__banner__marketplace-img' />
                          <img src={gallery.arrowRightImg} alt="" className='view__body__banner__arrow-img' />
                          <div className='view__body__banner__title'>{bannerTitle}</div>
                      </div>
                  </div>
              </div>
      );
    }
  };

  const renderMainMenu = (includeHeader: boolean) => {
    return (<div className="d-flex flex-column flex-shrink-0 p-3 view__sidebar">
            {includeHeader && renderHeader()}
            <ul className="nav nav-pills flex-column mb-auto">
                {renderCategory('Inventory', inventoryViews, 'view', AssetGallery.catalogueImg)}
                {renderSearchEngine('Marketplace', SearchType.MARKETPLACE, AssetGallery.marketplaceImg)}
                {activeUser && renderSearchEngine('Auctions', SearchType.AUCTION, AssetGallery.auctionsImg)}
                {isDevOrLocal && <Accordion title={'Deal Hub'} navLinks={c.APP_ROUTES.DEAL_HUB_LINKS} icon={AssetGallery.iconDeal}>
                    <NavLink className="nav-link view__sidebar__side-link" to="/admin/deal-hub">My Proposals</NavLink>
                    <NavLink className="nav-link view__sidebar__side-link" to="/admin/deal/create">Create New
                        Offer</NavLink>
                </Accordion>}
                <div className="view__sidebar__menu-divider"/>
                <span className="view__sidebar__section-title">VIEWS</span>
                {renderCategory('Info', informationViews, 'view', AssetGallery.infoImg)}
                {loggedInRole?.accessLevel === 0 && <>
                    <div className="view__sidebar__menu-divider"/>
                    <span className="view__sidebar__section-title">ADMIN</span></>}
                {renderAdminMenu()}
                {loggedInRole?.accessLevel === 1000 && <>
                    <div className="view__sidebar__menu-divider"/>
                    <span className="view__sidebar__section-title">CLIENT ADMIN</span>
                    {renderClientAdminMenu()}
                </>}
            </ul>
            <footer className="view__sidebar__footer__container">
                {configuration?.primaryLogo && <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${configuration?.primaryLogo}`} alt="EME Logo" className="view__sidebar__footer__logo"/>}
                {configuration?.clientTitle && <p className="view__sidebar__footer__content">Account: {configuration?.clientTitle}
                </p>}
                {activeUser?.firstName && <p className="view__sidebar__footer__content">User: {`${activeUser?.firstName} ${activeUser?.lastName}`}
                </p>}
                <div className="view__sidebar__menu-divider"/>
                <p className="view__sidebar__footer__content">
                    <a className="view__sidebar__footer__content" href={`${c.APP_ROUTES.CORE}/ExcessMaterialsExchange-Terms-Conditions.pdf`}>Terms & Conditions</a>
                </p>
                <p className="view__sidebar__footer__content">
                    <a className="view__sidebar__footer__content" href="/privacy">Privacy Policy</a>
                </p>
                <p className="view__sidebar__footer__content">
                    v.{appVersion} - <a className="view__sidebar__footer__content"
                                        href="mailto:support@excessmaterialsexchange.com">Contact
                    Support</a>
                </p>
                {loggedInRole && <p className="view__sidebar__footer__content">
                    <BugReportLink clientName={configuration?.clientTitle ?? 'Guest'}
                                   userName={activeUser?.username ?? 'Guest'} appVersion={appVersion ?? '0.0.0'}/>
                </p>}
                <p className="view__sidebar__footer__copyright">
                    &copy; {new Date().getFullYear()} EME. ALL Rights Reserved
                </p>
            </footer>
        </div>);
  };

  const toSubscriptions = () => {
    history.push(c.APP_ROUTES.SUBSCRIPTION);
  };

  return (<>
            <main>
                <div className="view">
                    <div className="view__navbar navbar navbar-light">
                        <button className="navbar-toggler view__navbar--border" type="button" onClick={() => setShowOffCanvas(true)}>
                            <span className="navbar-toggler-icon"/>
                        </button>
                      <div className='d-flex flex-row'>
                          {/*{onAddNew && <ImageButton iconSrc={AssetGallery.plusImg} label="Add New" onClick={onAddNew} />}*/}
                          {/*{(onEdit && !bannerType) && <ImageButton iconSrc={AssetGallery.editImg} label="Edit" onClick={onEdit} />}*/}
                          {activeUser ? <>
                                  <ProfileToggle user={activeUser}/>
                                  <ImageButton iconSrc={AssetGallery.logoutImg} label="Logout" onClick={onLogout}/>
                              </> :
                              <ImageButton iconSrc={AssetGallery.notloggedinImg} label="Login" onClick={onLogin}/>
                          }
                      </div>
                    </div>
                    <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                {renderHeader()}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {renderMainMenu(false)}
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className={`view__sidebar ${isLoading ? 'view__sidebar-disabled' : ''}`}>
                        {renderMainMenu(true)}
                    </div>
                    {!(showAdminSubscriptionPrompt || showUserSubscriptionPrompt) ? <div className="view__body">
                        <div className="view__body__header">
                            <div className="view__body__header-left">
                                <h2>{title}</h2>
                                {onAddNew &&
                                    <ImageButton iconSrc={AssetGallery.plusImg} label="Add New" onClick={onAddNew}/>}
                                {(onEdit && !bannerType) &&
                                    <ImageButton iconSrc={AssetGallery.editImg} label="Edit" onClick={onEdit}/>}
                            </div>
                            <div className="view__body__header-right">
                                {activeUser ?
                                    <>
                                        <ProfileToggle user={activeUser}/>
                                        <ImageButton iconSrc={AssetGallery.logoutImg} label="Logout"
                                                     onClick={onLogout}/>
                                    </>
                                  :
                                    <ImageButton iconSrc={AssetGallery.notloggedinImg} label="Login" onClick={onLogin}/>
                                }
                            </div>
                        </div>
                        {!isLoading && renderBanner(bannerType)}
                        {isLoading && <Loading/>}
                        <div className={isLoading ? 'mx-4 view__loading' : 'mx-4'}>{children}</div>
                    </div> :
                      showAdminSubscriptionPrompt ?
                        <div className={isLoading ? 'mx-4 view__loading' : 'mx-4'}>
                            <Modal closable={false} show={activeUser != null} title={'Subscription Required'}
                                   cta={'Update'} onCta={toSubscriptions}
                                   secondaryCta={'Logout'} onSecondaryCta={onLogout}>
                                This client does not currently have an active subscription. Please contact {activeClient?.billingContact || 'your designated billing contact'} or click the upgrade button to manage the subscription yourself.
                            </Modal>
                        </div> :
                            <div className={isLoading ? 'mx-4 view__loading' : 'mx-4'}>
                                <Modal closable={false} show={activeUser != null} title={'Subscription Required'}
                                       cta={'Logout'} onCta={onLogout}>
                                    This client does not currently have an active subscription. Please contact {activeClient?.billingContact || 'your designated billing contact'} to upgrade your subscription.</Modal>
                            </div>
                    }
                </div>
            </main>
        </>
  );
};

export default View;
